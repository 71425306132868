.resume-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.resume-container .resume-data {
  width: 98%;
  height: 95%;
  background: #fff;
  display: flex;
  flex-direction: row;
}