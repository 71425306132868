.dashboard-container {
  width: 100%;
  height: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-container .dashboard-header {
  width: 95%;
  height: auto;
  background: #fff;
}

.dashboard-container .dashboard-header .options-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 5em;
  margin-top: 2em;
  margin-bottom: 1em;
  gap: 2em;
}

.dashboard-container .dashboard-header .options-header  .title{
  font-size: 1.5em;
  font-weight: bold;
}


.dashboard-container .dashboard-header .filter-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1em 5em;
  margin-bottom: 1em;
}

.dashboard-container .dashboard-header .filter-header .date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5em;
  width: 20%;
}

.dashboard-container .dashboard-header .filter-header .date-container span {
  color: #000;
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 1em;
}

.dashboard-container .dashboard-header .filter-header .date-container input {
  background: #eaecef;
  height: 2em;
  border-radius: 0.8em;
  text-align: right;
  font-size: 1.6em;
}

.dashboard-container .dashboard-header .filter-header .customer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.dashboard-container .dashboard-header .filter-header .customer-container span {
  color: #000;
  font-size: 1.4em;
  font-weight: 600;
}

.dashboard-container .dashboard-header .filter-header .customer-container .select-input {
  height: 4em;
  width: 60%;
  display: initial;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4em;
  padding-top: 1em;
}

.dashboard-container .dashboard-header .filter-header .search-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 10%;
  height: 5em;
  cursor: pointer;
}

.dashboard-container .dashboard-header .filter-header .search-container img {
  width: 2.5em;
  height: 2.5em;
}

.dashboard-container .dashboard-cards {
  width: 95%;
  height: 85%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 1em 5em;
  margin-bottom: 1em;
  justify-content: center;
}

.dashboard-container .dashboard-cards .card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 25em;
  height: 10em;
  background: #fff;
  border-radius: 0.8em;
  margin: 2em;
}

.dashboard-container .dashboard-cards .card .card-image-container {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-container .dashboard-cards .card .card-image-container img {
  width: 4em;
  height: 4em;
}

.dashboard-container .dashboard-cards .card .card-content {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard-container .dashboard-cards .card .card-content .title {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.dashboard-container .dashboard-cards .card .card-content .title span {
  color: #000;
  font-size: 1.7em;
  font-weight: 600;
}

.dashboard-container .dashboard-cards .card .card-content .content {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.dashboard-container .dashboard-cards .card .card-content .content span {
  color: #000;
  font-size: 1.5em;
  font-weight: 500;
}

.info-button {
  position: absolute;
  bottom: 3%;
  right: 1%;

  border-radius: 50%;
  background: #5cc6d0;

  width: 4em;
  height: 4em;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.info-button span{
  font-size: 2.5em;
  font-weight: bold;
  color: #000;
}

.info-button:hover{
  opacity: 0.7;
}

@media (max-width: 425px) {
  .dashboard-container .dashboard-header {
    display: none;
  }

  .dashboard-container .dashboard-cards {
    justify-content: center;
    padding: 1em 1em;
  }

  .dashboard-container .dashboard-cards .card {
    margin-bottom: 1.5em;
  }
}