.drepage-container {
  width: 100%;
  height: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drepage-container .drepage-header {
  background: #fff;
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.drepage-container .drepage-header .date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5em;
  width: 20%;
}

.drepage-container .drepage-header .date-container span {
  color: #000;
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 1em;
}

.drepage-container .drepage-header .date-container input {
  background: #eaecef;
  height: 2em;
  border-radius: 0.8em;
  text-align: right;
  font-size: 1.6em;
}

.drepage-container .drepage-header .customer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.drepage-container .drepage-header .customer-container span {
  color: #000;
  font-size: 1.4em;
  font-weight: 600;
}

.drepage-container .drepage-header .customer-container .select-input {
  height: 4em;
  width: 60%;
  display: initial;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4em;
  padding-top: 1em;
}

.drepage-container .drepage-header .search-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 10%;
  height: 5em;
  cursor: pointer;
}

.drepage-container .drepage-header .search-container img {
  width: 2.5em;
  height: 2.5em;
}

.drepage-container .dre-body {
  margin-top: 4em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.small-widget-box-dre {
  width: 100%;
  height: 100%;
  overflow: auto;
  text-align: center;
}

.table-grid-dre {
  display: grid;
  grid-template-columns: 
  minmax(11em, 35em)
  minmax(8em, 25em)
  minmax(4em, 15em);
  overflow: auto;
  max-width: 100vw;
  max-height: 100vh;
  justify-content: center;
  /* max-height: 100%; */
  /* max-width: 100%; */
}

.table-grid-dre div {
  border: 1px solid #333;
  z-index: 0;
}

.table-grid-dre div.fixedToTop {
  z-index: 0;
}

.table-grid-dre div.fixedToLeft {
  z-index: 0;
}

.table-grid-dre div.fixedToBoth {
  top: 0;
  z-index: 6;
}

.table-grid-dre .header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(92, 198, 208);
  font-weight: bold;
  font-size: 1.8em;
  height: 4em;
  grid-column-start: 1;
  grid-column-end: 4;
}

.body-table-dre {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6em;
}

.table-dre-type {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8em;
  word-wrap: normal;
  border: none !important;
  
}

.table-dre-type .title {
  font-size: 1.6em;
  font-weight: 600 !important;
}

.table-dre-type .money {
  font-size: 1.9em;
  font-weight: 500;
}

.table-dre-type .percentage {
  font-size: 1.9em;
  font-weight: 500;
}

.export-icon-dre {
  position: absolute;
  top: -2%;
  right: -1%;
  z-index: 10;
  width: 3em;
  height: 3em;
  cursor: pointer;
}

@media (max-width: 1129px) {
  .table-dre-type {
    width: 80%;
  }
}

@media (max-width: 999px) {
  .table-dre-type {
    width: 90%;
  }
}

@media (max-width: 809px) {
  .table-dre-type {
    width: 100%;
  }
}

@media (max-width: 756px) {
  .arrow-up-icon {
    display: none;
  }

  .arrow-down-icon {
    display: none;
  }

  .table-dre-type {
    text-align: start;
    white-space: normal;
  }
}

@media (max-width: 722px) {
  .drepage-container .drepage-header {
    display: none;
  }
}


@media (max-width: 425px) {
  .table-dre-type {
    font-size: 1em;
  }

  .table-dre-type span{
    font-size: 1em;
  }
}