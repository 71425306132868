#login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(../../assets/images/loginbg.jpeg);
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: 0% 0%;
  background-size: 100% 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.form-box {
  top: 0;
  left: 50;
  background: rgba(255,255,255,0.7);
  width: 45em;
  height: 50em;
  border-radius: 1.5rem;
  padding: 2em 2em;
}

.form-box .logo-container {
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-box .logo-container img {
  width: 30em;
  height: 5em;
}

.form-box .form {
  height: 80%;
  padding: 0 5em 5em 5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-box .form .input-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
}

.form-box .form .input-box label{
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.form-box .form .input-box input{
  font-size: 1.5em;
  padding: 0 0.5em;
  width: 100%;
  height: 3em;
  border-radius: 0.5em;
  background: rgba(255,255,255,0.8);
  border: 0.1rem solid;
}

.form-box .form .input-box input:focus{
  outline: none !important;
  border: 0.3rem solid rgb(92,198,208);
}

.form-box .form button {
  width: 100%;
  height: 3em;
  border-radius: 0.5rem;
  background: rgb(92,198,208);
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
}

.form-box .form button:hover {
  cursor: pointer;
  opacity: 0.5;
}

.recovery-passwd {
  margin-top: 1em;
  text-align: center;
  color: rgb(16, 57, 61);
  font-size: 1.2em;
  cursor: pointer;
}

.recovery-passwd:hover {
  color:  rgba(16, 57, 61, 0.6);
}

@media (max-width: 540px) {
  .form-box {
    width: 40em;
  }
}

@media (max-width: 392px) {
  .form-box {
    width: 35em;
    padding: 1em 0em;
  }
}

@media (max-width: 357px) {
  .form-box {
    width: 32em;
    padding: 1em 0em;
  }
}