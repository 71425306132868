.users-container {
  width: 100%;
  height: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.users-container .users-header {
  width: 95%;
  height: 15%;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1em 5em;
  margin-bottom: 1em;
}

.users-container .users-header .header-btn{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 12em;
  height: 3em;
  border-radius: 0.8em;
  background: #349199;
  color: #fff;
  font-size: 1.3em;
  font-weight: bold;
}

.users-container .users-header .header-btn:nth-child(1){
  margin-right: 4em;
}

.users-container .users-header .header-btn:hover{
  cursor: pointer;
  opacity: 0.7;
}

.users-container .users-body {
  width: 95%;
  height: 85%;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.users-container .users-body .table-header {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
}
.users-container .users-body .table-header div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
}

.users-container .users-body .table-header div{
  width: 20%;
}

.users-container .users-body .table-header div:nth-child(2){
  width: 30%;
}

.users-container .users-body .table-header div:nth-child(4){
  width: 10%;
}

.users-container .users-body .table-header div span{
  font-weight: bold;
  font-size: 1.6em;
}

.users-container .users-body .table-body {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.users-container .users-body .table-body .line {
  width: 100%;
  height: 10em;
  padding: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.users-container .users-body .table-body .line div {
  width: 20%;
  padding: 0 1em;
  display: flex;
  justify-content: center;
}

.users-container .users-body .table-body .line div:nth-child(2){
  width: 30%;
}

.users-container .users-body .table-body .line div:nth-child(4){
  width: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 2em;
}

.users-container .users-body .table-body .line div:nth-child(5){
  width: 20%;
  padding: 0 5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.users-container .users-body .table-body .line div span{
  font-size: 1.4em;
}


.users-container .users-body .table-body .line div .eye-icon {
  cursor: pointer;
  width: 2.5em;
  height: 2.5em;
}

.users-container .users-body .table-body .line div .trash-icon {
  cursor: pointer;
  width: 2em;
  height: 2em;
}

.users-container .users-body .table-body .line div .passwd-icon {
  cursor: pointer;
  width: 2.5em;
  height: 2.5em;
}

.users-container .users-body .table-body .line div .finance-icon {
  cursor: pointer;
  width: 2.5em;
  height: 2.5em;
}

/* .users-container .users-body .table-body .line div .switch-btn {
  width: 2em;
  height: 2em;
} */