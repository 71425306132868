.parameter-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.parameter-form-container .box-container {
  background: #fff;
  margin-top: 1em;
  width: 90%;
  height: 70em;
  display: flex;
  flex-direction: column;
}

.parameter-form-container .box-container .back-container .back-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 10em;
  background: #349199;
  border-radius: 0.7em;
  margin: 1em 0 0 1em;
  padding: 0 0.5em;
  cursor: pointer;
}

.parameter-form-container .box-container .back-container .back-button:hover {
  opacity: 0.7;
}

.parameter-form-container .box-container .back-container .back-button span {
  font-size: 1.4em;
  margin-left: 1em;
  font-weight: bold;
  color: #ffffffe6;
}

.parameter-form-container .box-container  .form-container {
  display: flex;
  flex-direction: column;
}


.parameter-form-container .box-container  .form-container .content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.parameter-form-container .box-container  .form-container .content2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.parameter-form-container .box-container  .form-container .content2 .select-input {
  height: 4em;
  width: 90%;
  display: initial;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.5em;
  margin-top: 1em;
}

.parameter-form-container .box-container  .form-container .content span{
  display: flex;
  flex-direction: column;
  font-size: 1.6em;
  font-weight: bold;
  margin: 2em 0;
}

.parameter-form-container .box-container  .form-container .content2 span{
  display: flex;
  flex-direction: column;
  font-size: 1.6em;
  font-weight: bold;
}

.parameter-form-container .box-container  .form-container .content input{
  width: 90%;
  height: 3em;
  border: 2px solid #ccc;
  padding: 1em;
  border-radius: 0.7em;
  transition: all 0.5s ease;
  margin-bottom: 1.5em;
}

.parameter-form-container .box-container  .form-container .content input:focus{
  outline: none;
  border: 2px solid #349199;
  transition: all 0.5s ease;
}

.parameter-form-container .box-container  .form-container .footer {
  margin-top: 4em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.parameter-form-container .box-container  .form-container .footer button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.parameter-form-container .box-container  .form-container .footer .cancel-btn{ 
  width: 10em;
  height: 2.5em;
  background: #ccc;
  color: black;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
}

.parameter-form-container .box-container  .form-container .footer .save-btn{ 
  width: 10em;
  height: 2.5em;
  background: #349199;
  color: #fff;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
}