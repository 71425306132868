
.payments-loading{
  position: absolute;
  width: 90% !important;
  height: 90% !important;

  top: 2%;
  right: 0;
  left: 5%;
}

.payments-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  padding: 2em 5em 2em 5em;
}

.payments-header-container {
  margin-top: 2em;
  background: #fff;
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  padding: 1em;

  gap: 2em;
}

.payments-header-container .item {
  display: flex;
  flex-direction: row;

  column-gap: 1em;
}

.payments-header-container .item .title{
  font-size: 2em;
  font-weight: bold;
}

.payments-header-container .item .content {
  font-size: 2em;
}

.payments-header-container .item .red {
  color: red;
}

.payments-header-container .item .green {
  color: green;
}

.payments-header-container .cancel-signature {
  cursor: pointer;
  width: 12em;
  height: 2em;
}

.payments-header-container .cancel-signature{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 12em;
  height: 3em;
  border-radius: 0.8em;
  background: #6f6d6d;
  color: #fff;
  font-size: 1.3em;
  font-weight: bold;
}

.payments-header-container .cancel-signature:nth-child(1){
  margin-right: 4em;
}

.payments-header-container .cancel-signature:hover{
  cursor: pointer;
  opacity: 0.7;
}



.payments-table-container {
  background-color: #fff;
  margin-top: 8em;

  padding-top: 2em;

  height: 100%;

  display: flex;
  flex-direction: column;

  gap: 7em;
}

.payments-table-container .title {
  align-self: center;
  justify-self: center;

  font-weight: 600;

  font-size: 3em;
}

.payments-table-container .table-container {
}



@media (max-width: 810px) {
  .payments-header-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;    
  }
}

@media (max-width: 647px) {
  .payments-table-container {
    display: none;
  }
}

@media (max-width: 600px) {
  .payments-form-container {
    padding: 2em 3em;
  }
}