.close-menu-container-dre {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5em;
  height: 3.8em;
  border-radius: 0.8em 0 0.8em 0.8em;
  background: #fff;
  position: fixed;
  top: 32%;
  right: 1%;
  cursor: pointer;
}

.close-menu-container-dre img {
  width: 3em;
  height: 3em;
}

.menu-container-dre {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.6);
  z-index: 11;
  display: flex;
  flex-direction: row;
}

.menu-container-dre .close-filter {
  height: 100%;
  width: 80%;
  z-index: 12;
  top: 0;
  left: 0;
  background-color: transparent;
  overflow-x: hidden;
  padding: 10px;
}

.menu-container-dre .side-filter {
  height: 100%;
  width: 20%;
  z-index: 12;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.menu-container-dre .side-filter .side-filter-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 1em;
}

.menu-container-dre .side-filter .side-filter-header .close-icon {
  width: 1.2em;
  height: 1.2em;
  cursor: pointer;
}

.menu-container-dre .side-filter .customer-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1.5em;
}

.menu-container-dre .side-filter .customer-container span{
  color: #000;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 1em;
}

.menu-container-dre .side-filter .customer-container .select-input{
  height: 4em;
  width: 100%;
  display: initial;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4em;
}

.menu-container-dre .side-filter .year-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1.5em;
}

.menu-container-dre .side-filter .year-container span{
  color: #000;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 1em;
}

.menu-container-dre .side-filter .year-container .select-input{
  height: 4em;
  width: 100%;
  display: initial;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4em;
}

.menu-container-dre .side-filter .search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  width: 100%;
  height: 5em;
  cursor: pointer;
}

.menu-container-dre .side-filter .search-container:hover {
  opacity: 0.7;
}

.menu-container-dre .side-filter .search-container span  {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 3em;
  border-radius: 0.8em;
  background: #349199;
  color: #fff;
  font-size: 1.3em;
  font-weight: bold;
}

.menu-container-dre .side-filter .search-container span img {
  width: 2.5em;
  height: 2.5em;
}

.menu-container-dre .side-filter .export-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  width: 100%;
  height: 5em;
  cursor: pointer;
}

.menu-container-dre .side-filter .export-container:hover {
  opacity: 0.7;
}

.menu-container-dre .side-filter .export-container span  {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 3em;
  border-radius: 0.8em;
  background: #499b68;
  color: #fff;
  font-size: 1.3em;
  font-weight: bold;
}

.menu-container-dre .side-filter .export-container span img {
  width: 2.5em;
  height: 2.5em;
}

@media (max-width: 885px) {

  .menu-container-dre .close-filter {
    width: 70%;
  }

  .menu-container-dre .side-filter {
    width: 30%;
  }

}


@media (max-width: 600px) {

  .menu-container-dre .close-filter {
    width: 60%;
  }

  .menu-container-dre .side-filter {
    width: 40%;
  }

}

@media (max-width: 450px) {

  .menu-container-dre .close-filter {
    width: 50%;
  }

  .menu-container-dre .side-filter {
    width: 50%;
  }

}

@media (max-width: 425px) {
  .menu-container-dre .close-filter {
    width: 40%;
  }

  .menu-container-dre .side-filter {
    left: 40%;
    width: 60%;
  }

}

@media (min-width: 722px) {
  .close-menu-container-dre {
    display: none;
  }

}