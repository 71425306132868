.back-button-container .back-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 10em;
  height: 2em;
  background: #349199;
  border-radius: 0.7em;
  margin: 1em 0 0 1em;
  padding: 0 0.5em;
  cursor: pointer;
}

.back-button-container .back-button:hover {
  opacity: 0.7;
}

.back-button-container .back-button span {
  font-size: 1.4em;
  margin-left: 1em;
  font-weight: bold;
  color: #ffffffe6;
}