#menu-header {
  background: #fff;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 4em 0 2em;
}

#menu-header .sidebar-mobile {
  display: none;
}

#menu-header .notification-container {
  position: absolute;
  top: 0.8%;
  right: 20%;
  width: 5em;
  height: 4.9em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

#menu-header .notification-container .notification-icon {
  width: 2em;
  height: 2em;
}

#menu-header .notification-container .notification {
 position: absolute;
 top: 10%;
 right: 15%;
 width: 1.6em;
 height: 1.6em;
 background: red;
 border-radius: 0.5em;
 display: flex;
 align-items: center;
 justify-content: center;
}

#menu-header .notification-container .notification span {
  text-align: center;
  color: #fff;
  font-size: 0.8em;
  font-weight: bold;
 }

#menu-header .avatar-container {
  border-radius: 50%;
  width: 4.6em;
  height: 4.4em;
  border: 1px solid rgb(92,198,208);
  position: absolute;
  right: 5%;
}

#menu-header .avatar-container .avatar {
  border-radius: 50%;
  width: 4.6em;
  height: 4.4em;
}

#menu-header .avatar-container .down-arrow {
  position: absolute;
  bottom: 0;
  right: -6%;
  width: 1.6em;
  height: 1.6em;
  z-index: 9;
  background: rgb(92,198,208);
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#menu-header .avatar-container .down-arrow img {
  width: 1.1em;
  height: 1.1em;
}

#menu-header .profile-menu {
  position: absolute;
  background: rgb(92,198,208);
  top: 11%;
  right: 1%;
  width: 100px;
  height: 100px;
  transition: all 0.7s;
  border-radius: 1em;
  padding: 0.5em 0;
  z-index: 11;
}

#menu-header .profile-menu-inactive {
  position: absolute;
  background: rgb(92,198,208);
  top: 10%;
  right: 1%;
  width: 100px;
  height: 0px;
  transition: all 0.3s;
  z-index: 9;
}


#menu-header .profile-menu .menu-link {
  width: 100%;
  height: 4.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

#menu-header .profile-menu .menu-link:hover {
  background: #349199
}

#menu-header .profile-menu .menu-link .menu-icon {
  width: 2em;
  height: 2em;
  margin: 0 0.5em 0 2.5em;
}

#menu-header .profile-menu .menu-link .link{
  font-size: 1.3em;
  font-weight: 600;
  color: #fff;
}

.close-profile-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}


@media (max-width: 425px) {
  #menu-header .sidebar-mobile {
    display: flex;
  }

  #menu-header .profile-menu {
    height: 50px;
  } 

  #menu-header .profile-menu #profile {
    display: none;
  }
}