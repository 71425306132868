.menu-mobile-icon {
  width: 2.8em;
  height: 2.8em;
}

#sidebar-close-conteiner {
  display: none;
}

#sidebar-open-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 11;
  display: flex;
  flex-direction: row;
  background: transparent;
}

#sidebar-open-container .sidebar {
  height: 100%;
  width: 35%;
  z-index: 12;
  top: 0;
  left: 0;
  background-color: rgb(92,198,208);
  overflow-x: hidden;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}

#sidebar-open-container .close-sidebar {
  height: 100%;
  width: 65%;
  z-index: 12;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-x: hidden;
  padding-top: 20px;
}

#sidebar-open-container .sidebar .sidebar-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 1em;
}

#sidebar-open-container .sidebar .sidebar-header .close-icon {
  width: 1.2em;
  height: 1.2em;
}

#sidebar-open-container .sidebar .menu-list {
  display: flex;
  flex-direction: column;
  margin-top: 4em;
  width: 100%;
}

#sidebar-open-container .sidebar .menu-list:last-child {
  margin-top: 2em;
}

#sidebar-open-container .sidebar .menu-list .menu-title{
  display: flex;
  flex-direction: column;
  margin-bottom: 0.6em;
  padding: 0 1em;
}

#sidebar-open-container .sidebar .menu-list .menu-title span{
  font-size: 1.2em;
  font-weight: bold;
  color: rgba(255,255,255,0.8)
}

#sidebar-open-container .sidebar .menu-list .menu-link {
  width: 100%;
  height: 4.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

#sidebar-open-container .sidebar .menu-list .menu-link:hover {
  background: #349199
}

#sidebar-open-container .sidebar .menu-list .menu-link .menu-icon {
  width: 2em;
  height: 2em;
  margin: 0 0.5em 0 1.8em;
}

#sidebar-open-container .sidebar .menu-list .menu-link .link{
  font-size: 1.2em;
  font-weight: 600;
  color: #fff;
}

#sidebar-open-container .sidebar .menu-list .menu-link-active {
  width: 100%;
  height: 4.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  background: #349199
}

#sidebar-open-container .sidebar .menu-list .menu-link-active .menu-icon {
  width: 2em;
  height: 2em;
  margin: 0 0.5em 0 1.8em;
}

#sidebar-open-container .sidebar .menu-list .menu-link-active .link{
  font-size: 1.2em;
  font-weight: 600;
  color: #fff;
}

#sidebar-open-container .sidebar .menu-list .line{
  margin-top: 1em;
  width: 100%;
  border-bottom: 0.1em ridge rgba(255,255,255,0.4);
}
