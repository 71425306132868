.modal-confirm-recourrence {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.4);
  z-index: 20;
}


.modal-confirm-recourrence .off-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.4);
  z-index: 21;
}


.modal-confirm-recourrence .on-container {
  position: absolute;
  top: 25%;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 50rem;
  height: 40rem;
  background-color: #fff;
  z-index: 22;

  border-radius: 1em;
  padding: 2em;
  display: flex;
  flex-direction: column;

  gap: 2.6em;
}

.modal-confirm-recourrence .on-container header {
  align-self: center;
  height: auto;
}

.modal-confirm-recourrence .on-container body {
  font-size: 2em;
  padding: 0 1em;
}

.modal-confirm-recourrence .on-container body div {
  margin-top: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.6em;
}

.modal-confirm-recourrence .on-container footer  {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


.modal-confirm-recourrence .on-container footer button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.modal-confirm-recourrence .on-container footer .cancel-btn{ 
  width: 10em;
  height: 3em;
  background: #ccc;
  color: black;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
}

.modal-confirm-recourrence .on-container footer .save-btn{ 
  width: 10em;
  height: 3em;
  background: #349199;
  color: #fff;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
}

@media (max-width: 600px) {
  .modal-confirm-recourrence .on-container {
    width: 90%;
  }
}


@media (max-width: 412px) {
  .modal-confirm-recourrence .on-container {
    width: 95%;
  }
}

@media (max-width: 370px) {
  .modal-confirm-recourrence .on-container {
    height: 45rem;
  }
}