.modal-delete-recourrence {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.4);
  z-index: 20;
}


.modal-delete-recourrence .off-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.4);
  z-index: 21;
}


.modal-delete-recourrence .on-container {
  position: absolute;
  top: 25%;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 50rem;
  height: 60rem;
  background-color: #fff;
  z-index: 22;

  border-radius: 1em;
  padding: 2em;
  display: flex;
  flex-direction: column;

  gap: 2.6em;
}

.modal-delete-recourrence .on-container header {
  align-self: center;
  height: auto;
  font-size: 1.4em;
}

.modal-delete-recourrence .on-container div {
  font-size: 2em;
  padding: 0 1em;
  height: 90%;
  overflow-y: scroll;
  padding-top: 1em;
}



.modal-delete-recourrence .on-container div table {
  margin-top: 1em;
}

.modal-delete-recourrence .on-container div table td{
  font-size: 0.8em !important;
}

.modal-delete-recourrence .on-container div table tr:nth-child(even) {
  background-color: #dddddd;
}


input[type="checkbox"] {
  cursor: pointer;
}


.modal-delete-recourrence .on-container footer  {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 10%;
}


.modal-delete-recourrence .on-container footer button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.modal-delete-recourrence .on-container footer .cancel-btn{ 
  width: 10em;
  height: 3em;
  background: #ccc;
  color: black;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
}

.modal-delete-recourrence .on-container footer .save-btn{ 
  width: 10em;
  height: 3em;
  background: #f15c68;
  color: #fff;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
}



@media (max-width: 600px) {
  .modal-delete-recourrence .on-container {
    width: 90%;
  }
}


@media (max-width: 412px) {
  .modal-delete-recourrence .on-container {
    width: 95%;
  }
}

@media (max-width: 370px) {
  .modal-delete-recourrence .on-container {
    height: 45rem;
  }
}