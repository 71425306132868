.refresh-payment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.refresh-payment-container .content {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 20px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 20em;
}

.refresh-payment-container .content span {
  font-weight: bold;
  font-size: 40px;
}

.refresh-payment-container .content p {
  font-weight: 500;
  font-size: 20px;
}

.refresh-payment-btn {
  background-color: rgb(92, 198, 208);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.refresh-payment-btn-loading {
  background-color: grey;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: not-allowed;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.refresh-payment-btn:hover {
  background-color: rgba(92, 198, 208, 0.5);
}


/* NEW */

.layout {
  display: flex;
  flex-direction: row;
}

.image {
  width: 50%;
  height: 100vh;
  background: rgb(92,198,208);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image .logo {
  width: 40%;
  height: 20%;
}

.message {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 50px;
}

.messages {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (max-width: 768px) {
  .layout {
    flex-direction: column;
  }

  .image {
    width: 100%;
    height: 30vh;
  }

  .image .logo {
    width: 40%;
    height: 80%;
  }

  .message {
    width: 100%;
  }
}
