.currency-container {
  border:1px solid #ccc;
  border-radius: 0.4em;
  width: 100%;
  height: 4em;
  display: flex;
  flex-direction: row;
  padding-left: 1em;
}

.currency-container .prefix-span {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-size: 1.4em;
}

.currency-container .currencyInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: end;
  font-size: 1.4em;
  width: 100%;
  height: 100%;
  padding: 0 2em;
}

.currency-container .currencyInput:focus {
  outline: none ;
}