.common-btn {
  width: 10em;
  height: 3em;

  background-color: green;;
  color: #fff;

  border-radius: 4px;

  font-weight: bold;

  cursor: pointer;
}

.common-btn:hover {
  opacity: 0.8;
}