.background-options {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.close-options-container {
  position: absolute;
  top: -200%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  width: 40em;
  height: 20em;
  z-index: 11;
}

.options-container {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: rgba(245, 247,250, 1);
  width: 40em;
  height: 30em;
  z-index: 11;
  padding: 2em;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 1em;
}

.options-container .info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.info-container span {
  font-size: 15px;
}

.close-icon {
  width: 1.5em;
  height: 1.5em;

  position: absolute;
  top: 4%;
  right: 4%;

  cursor: pointer;

}

@media (max-width: 450px) {
  .options-container {
    width: 80%;
  }
}