:root {
  font-size: 60%;
  font-family: Poppins;
  --app-dark: rgb(35,74,92);
  --app-ligth-1: #9edbf3;  
  --app-ligth-2:  rgb(92,198,208);
  --app-ligth-3: rgb(84,195,236);
  --app-black: rgb(42, 49, 53);
  --app-background: #f5f7fa
}

* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  font-family: 'Rubik', sans-serif;
}

html, body, #root {
  height: 100vh;
  overflow: hidden;
}

#main {
  width: 100vw;
  height: 100vh;
  background: var(--app-background);
  display: flex;
  flex-direction: row;
}

.main-body {
  height: 100%;
  width: 85%;
  display: flex;
  flex-direction: column;
}


@media (max-width: 885px) {
  .main-body {
    width: 93%;
  }
}

@media (max-width: 425px) {
  .main-body {
    width: 100%;
  }
}