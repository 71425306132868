.small-widget-box {
  width: 100%;
  height: 100%;
  overflow: auto;
  text-align: center;
}

.table-grid {
  display: grid;
  grid-template-columns: repeat(13, minmax(11em, auto));
  max-width: 100vw;
  max-height: 100vh;
}

.table-grid div {
  border: 1px solid #333;
  z-index: 0;
}

.table-grid div.fixedToTop {
  z-index: 2;
}

.table-grid div.fixedToLeft {
  z-index: 2;
}

.table-grid div.fixedToBoth {
  top: 0;
  z-index: 6;
}

.table-grid .header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(92, 198, 208);
  font-weight: bold;
  font-size: 1.7em;
  height: 4em;
}

.table-grid .body-table-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.7em;
  height: 4em;
}

.body-table {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4em;
  font-size: 1.7em;
  padding: 0 0.4em;
}