.background-options {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.close-options-container {
  position: absolute;
  top: -200%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: #fff;
  width: 40em;
  height: 20em;
  z-index: 11;
}

.options-container {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: rgba(245, 247,250, 0.8);
  width: 40em;
  height: 20em;
  z-index: 11;
  padding: 2em;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.options-container .buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.options-container .buttons-container button {
  width: 70%;
  height: 2.5em;
  cursor: pointer;
  border-radius: 0.8em;
  color: #fff;
  font-size: 1.4em;
}

.options-container .buttons-container button:hover {
  opacity: 0.6;
}

.options-container .buttons-container button:nth-child(1) {
  background-color: #349199;
  margin-bottom: 1em;
}

.options-container .buttons-container button:nth-child(3) {
  margin-top: 1em;
  background-color: rgb(240, 105, 105);
}

.options-container .buttons-container span {
  font-size: 1.4em;
}

@media (max-width: 450px) {
  .options-container {
    width: 80%;
  }
}