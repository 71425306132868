.status-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0.5em;
  height: 1.5em;
  cursor: pointer;
}

.status-container span {
  font-size: 1.2em;
}