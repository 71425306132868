#toast-container {
  position: fixed;
  top: 1%;
  right: 0;
  width: 33rem;
  height: 8rem;
  z-index: 30;
  transition: all 0.8s;
}

#toast-container-hidden {
  position: fixed;
  top: 1%;
  right: -150%;
  width: 35rem;
  height: 10rem;
  z-index: 30;
  transition: all 0.8s;
}

.toast-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.toast-content .icon-container {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toast-content .icon-container img {
  width: 3em;
  height: 3em;
}

.toast-content .description-container {
  width: 85%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.6em;
}

.toast-content .description-container span{
  font-size: 1.7em;
}

.close-img {
  top: 1%;
  right: 3%;
  position: absolute;
  width: 2.5em;
  height: 2.5em;
  cursor: pointer;
}