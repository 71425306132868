.close-order-page {
  display: none;
}

.order-page {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-order-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-container {
  display: inline-block;
  position: absolute;
  width: 40em;
  height: 60em;
  background: #fff;
  z-index: 20;
  top: 1%;
}

.order-container .grid-zone {
  display: flex;
  flex-wrap: wrap;
  height: 400px;
  height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
}


.order-container .grid-zone .grid-item-card .grid-item-container {
  width: 100%;
  height: 100%;
  background-color: rgb(92, 198, 208);
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  font-size: 1.5em;
  color: #000;
}

.order-container .grid-zone .grid-item-card .grid-item-container:hover {
  background-color: rgb(92, 198, 208, 0.5);
}

.order-container .footer {
  margin-top: 1em;
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.order-container .footer button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.order-container .footer .cancel-btn {
  width: 10em;
  height: 2.5em;
  background: #ccc;
  color: black;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
}

.order-container .footer .save-btn {
  width: 10em;
  height: 2.5em;
  background: #349199;
  color: #fff;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
}

.doubleClick-modal { 
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  width: 30em;
  height: 20em;
  background: white;
  z-index: 20;
  border: 2px solid red;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
}

.doubleClick-modal span { 
  align-self: center;
  padding: 1em 2em;
  font-size: 2em;
}

.doubleClick-modal input {
  width: 90%;
  align-self: center;
  height: 2em;
  border: 1px solid #ccc;
  font-size: 1.5em;
  padding: 0 0.6em;
  margin: 1em 0;
}

.doubleClick-modal div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.doubleClick-modal button {
  width: 10em;
  height: 2.5em;
  color: #fff;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
  cursor: pointer;
}

.doubleClick-modal .save {
  background: #349199;
}

.doubleClick-modal .cancel {
  background: #ccc;
}