.images-list-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-icon-modal {
  position: absolute;
  top: 1%;
  right: 1%;
  width: 1.2em;
  height: 1.2em;
  cursor: pointer;
}

.images-list-box {
  position: absolute;
  width: 50%;
  height: 80%;
  background: #fff;
  z-index: 20;
}

.scroll-div {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.images-list-box .table-header {
  width: 100%;
  height: 5em;
  border-bottom: 2px solid #349199;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.images-list-box .table-header span {
  width: 20%;
  text-align: center;
  font-size: 1.4em;
  font-weight: bold;
}

.images-list-box .table-body {
  width: 100%;
  height: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images-list-box .table-body .line {
  width: 100%;
  height: 5em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.images-list-box .table-body .line span {
  width: 20%;
  text-align: center;
  font-size: 1.4em;
}

@media (max-width: 768px) {
  .images-list-box {
    width: 80%;
  }
}

@media (max-width: 425px) {
  .images-list-box {
    width: 95%;
  }
}