.filter-button {
  display: none;
}

@media (max-width: 425px) {
  .filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    height: 3.8em;
    border-radius: 0.8em 0 0.8em 0.8em;
    background: #fff;
    position: fixed;
    top: 32%;
    right: 0;
  }

  .filter-button img {
    width: 2em;
    height: 2em;
  }

  .side-filter {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 11;
    display: flex;
    flex-direction: row;
    background: transparent;
  }

  .side-filter .sidebar {
    height: 100%;
    width: 50%;
    z-index: 12;
    top: 0;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .side-filter .sidebar .sidebar-header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 1em;
  }

  .side-filter .sidebar .sidebar-header .close-icon {
    width: 1.2em;
    height: 1.2em;
  }

  .side-filter .sidebar .date-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2em 0;
    width: 100%;
  }

  .side-filter .sidebar .date-container span {
    color: #000;
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 1em;
  }

  .side-filter .sidebar .date-container input {
    background: #eaecef;
    height: 2em;
    border-radius: 0.8em;
    text-align: right;
  }

  .side-filter .sidebar .customer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1.5em;
  }

  .side-filter .sidebar .customer-container span {
    color: #000;
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 1em;
  }

  .side-filter .sidebar .customer-container .select-input {
    height: 4em;
    width: 100%;
    display: initial;
    align-items: center;
    justify-content: flex-start;
  }

  .side-filter .sidebar .banks-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2em 0;
    width: 100%;
    gap: 2em;
  }

  .side-filter .sidebar .banks-container .title {
    color: #000;
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 1em;
  }

  .side-filter .sidebar .banks-container .item {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }

  .side-filter .sidebar .banks-container .item label {
    font-size: 1.2em;
  }

  .side-filter .sidebar .search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
    width: 100%;
    height: 5em;
    cursor: pointer;
  }

  .side-filter .sidebar .search-container span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 3em;
    border-radius: 0.8em;
    background: #349199;
    color: #fff;
    font-size: 1.3em;
    font-weight: bold;
  }

  .side-filter .sidebar .search-container span img {
    width: 2.5em;
    height: 2.5em;
  }

  .side-filter .close-sidebar {
    height: 100%;
    width: 50%;
    z-index: 12;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    overflow-x: hidden;
    padding-top: 20px;
  }
}