.profile-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.profile-form-container .box-container {
  background: #fff;
  margin-top: 1em;
  width: 98%;
  height: 98%;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: auto;
}

.profile-form-container .box-container .form-container {
  height: 100%;
  width: 60%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
}

.profile-form-container .box-container .form-container-edit {
  height: 80% !important;
}

.profile-form-container .box-container .form-container .form-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25em;
  height: 14em;
}

.profile-form-container .box-container .form-container .form-box .span-title {
  color: #000;
  font-size: 1.6em;
  font-weight: 600;
  margin-bottom: 1em;
  text-align: start;
  font-weight: bold;
}

.profile-form-container .box-container .form-container .form-box .span-title .required {
  color: red
}

.profile-form-container .box-container .form-container .form-box input{
  font-size: 1.4em;
}

.profile-form-container .box-container .form-container .form-box .input-date {
  background: #eaecef;
  height: 2em;
  border-radius: 0.8em;
  text-align: center;
  width: 14em;
}

.profile-form-container .box-container .form-container .form-box .select-input {
  height: 2.5em;
  width: 100%;
  display: initial;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.6em;
}

.profile-form-container .box-container .form-container .form-box .text-area-input {
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
}

.profile-form-container .box-container .form-container .form-box .text-input {
  border: 1.5px solid #ccc;
  width: 17em;
  height: 2.5em;
  border-radius: .3em;
  padding-left: 1em;
  outline: none;
}

.profile-form-container .box-container .form-container .form-box .text-input:focus {
  border-color: rgb(92, 198, 208);
}


.profile-form-container .box-container .footer {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.profile-form-container .box-container .footer button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.profile-form-container .box-container .footer .cancel-btn{ 
  width: 10em;
  height: 2.5em;
  background: #ccc;
  color: black;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
}

.profile-form-container .box-container .footer .save-btn{ 
  width: 10em;
  height: 2.5em;
  background: #349199;
  color: #fff;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
}

.profile-form-container .box-container .profile-info-container {
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 4em;
}

.profile-form-container .box-container .profile-image-container {
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-form-container .box-container .image-box {
  width: 80%;
  height: 70%;
  background-color: rgb(92,198,208);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.profile-form-container .box-container .image-box img{
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 50%;
}

.profile-form-container .box-container .image-footer{
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  padding-top: 2em;
  justify-content: flex-start;
  align-items: center;
}

.profile-form-container .box-container .image-footer button{
  width: 60%;
  height: 2em;
  background-color: #d1cfcfd2;
  cursor: pointer;
}

.profile-form-container .box-container .image-footer button:hover{
  opacity: 0.8;
}


.profile-form-container .box-container .profile-info-container .buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 20%
}

.profile-form-container .box-container .profile-info-container .buttons-container button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.profile-form-container .box-container .profile-info-container .buttons-container .save-btn{ 
  width: 10em;
  height: 2.5em;
  background: #349199;
  color: #fff;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
}

.profile-form-container .box-container .profile-info-container .change-password {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-form-container .box-container .profile-info-container .change-password .btn-change-password{ 
  width: 10em;
  height: 2.5em;
  background: #ccc;
  color: black;
  border-radius: 0.2em;
  font-size: 1.4em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0 1em;
  cursor: pointer;
}

.profile-form-container .box-container .profile-info-container .change-password .btn-change-password:hover{ 
  opacity: 0.6;
}

.span-title-bank {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.form-banks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-banks-childrens {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}