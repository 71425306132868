.cashflow-container {
  width: 100%;
  height: 100%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cashflow-container .cashflow-header {
  width: 95%;
  height: 15%;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1em 5em;
  margin-bottom: 1em;
}

.cashflow-container .cashflow-header .header-btn{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 12em;
  height: 3em;
  border-radius: 0.8em;
  background: #349199;
  color: #fff;
  font-size: 1.3em;
  font-weight: bold;
}

.cashflow-container .cashflow-header .header-btn:nth-child(1){
  margin-right: 4em;
}

.cashflow-container .cashflow-header .header-btn:hover{
  cursor: pointer;
  opacity: 0.7;
}

.cashflow-container .cashflow-body {
  width: 95%;
  height: 85%;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.cashflow-container .cashflow-body .table-buttons {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.cashflow-container .cashflow-body .table-buttons button{
  width: 10em;
  height: 2em;
  border-radius: 0.7em;
  background: #349199;
  color: #fff;
  font-size: 1.4em;
  cursor: pointer;
}

.cashflow-container .cashflow-body .table-buttons button:hover{
  opacity: 0.7;
}

.cashflow-container .cashflow-body .table-buttons .disabled-btn {
  background: #c6c6c6;
  cursor: not-allowed;
}

.cashflow-container .cashflow-body .table-content {
  width: 100%;
  height: 90%;
}


@media (max-width: 375px) {
  .cashflow-container .cashflow-header {
    padding: 1em 3em;
  }
}