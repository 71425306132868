.close-view-page {
  display: none;
}

.view-page {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-view-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-container {
  position: absolute;
  width: 40em;
  height: 40em;
  background: #fff;
  z-index: 20;
  padding: 2em;
}

.view-container .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

.view-container .content2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.view-container .content2 .select-input {
  height: 4em;
  width: 90%;
  display: initial;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.5em;
}

.view-container .content span {
  display: flex;
  flex-direction: column;
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 1em;
}

.view-container .content2 span {
  display: flex;
  flex-direction: column;
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 1em;
}

.view-container .content input {
  width: 90%;
  height: 3em;
  border: 2px solid #ccc;
  padding: 1em;
  border-radius: 0.7em;
  transition: all 0.5s ease;

}

.view-container .content input:focus {
  outline: none;
  border: 2px solid #349199;
  transition: all 0.5s ease;
}

.view-container .footer {
  margin-top: 2em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.view-container .footer button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.view-container .footer .cancel-btn {
  width: 10em;
  height: 2.5em;
  background: #ccc;
  color: black;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
}

.view-container .footer .save-btn {
  width: 10em;
  height: 2.5em;
  background: #349199;
  color: #fff;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
}