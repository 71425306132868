.dashboard-bankOptions-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2em;
}

.dashboard-bankOptions-container .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: auto;
  gap: 0.5em;
}

.dashboard-bankOptions-container .item label {
  font-size: 1.5em;
}