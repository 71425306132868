.whatsapp-button {
  position: absolute;
  bottom: 3%;
  right: 1%;

  border-radius: 50%;
  background: #29a319;

  width: 4em;
  height: 4em;

  padding: 1em;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.whatsapp-button-dash {
  position: absolute;
  bottom: 3%;
  right: 5%;

  border-radius: 50%;
  background: #29a319;

  width: 4em;
  height: 4em;

  padding: 1em;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.whatsapp-button:hover{
  opacity: 0.7;
}

.whatsapp-button-dash:hover{
  opacity: 0.7;
}