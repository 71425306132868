#sidebar {
  width: 15%;
  height: 100%;
  background: rgb(92,198,208);
  padding: 2em 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  -moz-box-shadow:    -3px 0 0.9em 0 #555;
  -webkit-box-shadow: -3px 0 0.9em 0 #555;
  box-shadow:         -3px 0 0.9em 0 #555;
}

#sidebar .logo-container {
  width: 14em;
  height: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
}

#sidebar .logo-container img {
  width: 14em;
  height: 6em;
  padding: 0 1.5em;
}

#sidebar .menu-list {
  display: flex;
  flex-direction: column;
  margin-top: 4em;
  width: 100%;
}

#sidebar .menu-list:last-child {
  margin-top: 2em;
}

#sidebar .menu-list .menu-title{
  display: flex;
  flex-direction: column;
  margin-bottom: 0.6em;
  padding: 0 1.5em;
}

#sidebar .menu-list .menu-title span{
  font-size: 1.2em;
  font-weight: bold;
  color: rgba(255,255,255,0.8)
}

#sidebar .menu-list .menu-link {
  width: 100%;
  height: 4.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

#sidebar .menu-list .menu-link:hover {
  background: #349199
}

#sidebar .menu-list .menu-link .menu-icon {
  width: 2.5em;
  height: 2.5em;
  margin: 0 0.5em 0 2.5em;
}

#sidebar .menu-list .menu-link .link{
  font-size: 1.4em;
  font-weight: 600;
  color: #fff;
}

#sidebar .menu-list .menu-link-active {
  width: 100%;
  height: 4.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  background: #349199;
}

#sidebar .menu-list .menu-link-active .menu-icon {
  width: 2.5em;
  height: 2.5em;
  margin: 0 0.5em 0 2.5em;
}

#sidebar .menu-list .menu-link-active .link{
  font-size: 1.4em;
  font-weight: 600;
  color: #fff;
}

#sidebar .menu-list .line{
  margin-top: 1em;
  width: 100%;
  border-bottom: 0.1em ridge rgba(255,255,255,0.4);
}

@media (max-width: 885px) {
  #sidebar {
    width: 7%;
  }

  #sidebar .logo-container {
    display: none;
  }

  #sidebar .menu-list .menu-title{
    display: none;
  }

  #sidebar .menu-list .menu-link .menu-icon {
    margin: 0 0.5em 0 1.5em;
  }

  #sidebar .menu-list .menu-link .link{
    display: none;
  }

  #sidebar .menu-list .menu-link-active .menu-icon {
    margin: 0 0.5em 0 1.5em;
  }

  #sidebar .menu-list .menu-link-active .link{
    display: none;
  }
}

@media (max-width: 650px) {
  #sidebar .menu-list .menu-link .menu-icon {
    margin: 0 0.1em 0 1em;
  }

  #sidebar .menu-list .menu-link-active .menu-icon {
    margin: 0 0.1em 0 1em;
  }
}

@media (max-width: 580px) {
  #sidebar .menu-list .menu-link .menu-icon {
    margin: 0 0.7em
  }

  #sidebar .menu-list .menu-link-active .menu-icon {
    margin: 0 0.7em
  }
}

@media (max-width: 500px) {
  #sidebar .menu-list .menu-link .menu-icon {
    margin: 0 0.4em
  }

  #sidebar .menu-list .menu-link-active .menu-icon {
    margin: 0 0.4em
  }
}

@media (max-width: 457px) {
  #sidebar .menu-list .menu-link .menu-icon {
    width: 2.2em;
    height: 2.2em;
  }

  #sidebar .menu-list .menu-link-active .menu-icon {
    width: 2.2em;
    height: 2.2em;
  }
}

@media (max-width: 425px) {
  #sidebar{
    display: none;
  }
}