table {
 width: 100%;
 height: 100%;
 
}

thead {
  height: 10%;
  font-size: 1.5em;
}

tbody {
  height: 90%;
  font-size: 1.4em;
}

th {
  width: 10%;
}

td {
  text-align: center;
}

.icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 0 1em;
}

.eye-icon {
  cursor: pointer;
  width: 2em;
  height: 2em;
}

.trash-icon {
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
}