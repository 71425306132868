.bg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 9;
}

.change-password-container {
  z-index: 10;
  position: absolute;
  top: 20%;
  left: 40%;
  width: 25%;
  height: 30em;
  background: #fff;
}

.change-password-container .header {
  width: 100%;
  height: 15%;
  border-bottom: 1px solid #349199;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 1em;
}

.change-password-container .header img {
  width: 4em;
  height: 4em;
}

.change-password-container .header span {
  font-size: 1.8em;
  font-weight: bold;
  margin-left: 1em;
}


.change-password-container .content {
  height: 65%;
  width: 100%;
  padding: 1em 3em;
}

.change-password-container .content .input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}

.change-password-container .content .input-container span {
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 0.8em;
  align-self: flex-start;
}

.change-password-container .content .input-container input {
  width: 100%;
  height: 2em;
  border: 1px solid #ccc;
  font-size: 1.5em;
  padding: 0 0.6em;
}

.change-password-container .content .input-container input:focus {
  outline: none;
  border: 1px solid #349199;
}

.change-password-container .footer {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.change-password-container .footer button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.change-password-container .footer .cancel-btn{ 
  width: 8em;
  height: 2.3em;
  background: #ccc;
  color: black;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
}

.change-password-container .footer .save-btn{ 
  width: 8em;
  height: 2.3em;
  background: #349199;
  color: #fff;
  border-radius: 0.6em;
  font-size: 1.4em;
  font-weight: bold;
}