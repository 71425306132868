.bank-button-container {
  margin-top: 2em;
  width: auto;
  height: 2em;
  background-color: #8dff8d;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 2em 1.2em;
  gap: 1em;

  border-radius: 1.2em;

  cursor: pointer;
}

.bank-button-container:hover {
  opacity: 0.7;
}

.bank-button-container .icon {
  height: 2em;
  width: 2em;

}

.bank-button-container .label {
  font-size: 1.6em;
  font-weight: 500;
}